import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import grey from "@material-ui/core/colors/grey";
import LinkIcon from "@material-ui/icons/Link";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./slick.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import InstallButton from "./InstallButton";
import { STORE_URL } from "./constants";
import utils from "../utils";
import i18n from "../../i18n";
import Loading from "../../components/Loading/loading";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    // backgroundColor: "red",
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarGutter: "stable",
    "&::-webkit-scrollbar": {
      width: "4px",
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(165, 165, 165, 1)",
      borderRadius: "12px",
      "&:hover": {
        background: "rgba(165, 165, 165, 0.8)"
      }
    },
    "@media (prefers-color-scheme: dark)": {
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(165, 165, 165, 1)",
        "&:hover": {
          background: "rgba(165, 165, 165, 0.8)"
        }
      }
    }
  },
  loadingContainer: {
    justifyContent: "center",
    alignItems: "center"
  },
  progress: {
    margin: theme.spacing.unit * 2
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "24px",
    marginLeft: "60px",
    marginRight: "60px",
    minHeight: "62px",
    height: "auto"
  },
  logo: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    width: "42px",
    marginRight: "16px"
  },
  title: {
    display: "flex",
    flexDirection: "column"
  },
  appName: {
    fontWeight: 600,
    color: "#000",
    fontSize: "18px",
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    }
  },
  devName: {
    color: "#999",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  linkIcon: {
    marginLeft: theme.spacing.unit,
    cursor: "pointer",
    color: grey[400],
    "&:active": {
      color: "#0b9cda"
    },
    verticalAlign: "middle"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingLeft: "60px",
    paddingRight: "calc(60px - 4px)", 
    marginTop: "24px",
  },
  divider: {
    margin: '16px 0',
    height: '1px',
    "@media (prefers-color-scheme: dark)": {
      backgroundColor: "rgba(255, 255, 255, 0.12)"
    }
  },
  screenshot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "500px",
    height: "300px",
    marginRight: "20px",
    flex: "none",
    borderRadius: "8px", 
    overflow: "hidden",
    "&:last-child": {
      marginRight: "0",
    },
    "@media (max-width: 768px)": {
      width: "100%",
      minWidth: "280px",
      height: "300px",
    }
  },
  screenshotWrapper: {
    width: "100%",
    margin: "0 auto",
    position: "relative",
    padding: "0 60px",
    minWidth: "300px",
    "& .react-horizontal-scrolling-menu--scroll-container": {
      "&::-webkit-scrollbar": {
        display: "none"
      },
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      overflowX: "auto",
    },
    "@media (max-width: 768px)": {
     padding: "0 60px",
    }
  },
  screenshotImage: {
    // width: "100%",
    height: "100%",
    borderRadius: "8px",
    objectFit: "fill",
  },
  arrow: {
    cursor: "pointer",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    padding: "10px",
    boxSizing: "border-box",
    borderRadius: "50%",
    color: "#000",
    zIndex: 10,
    width: "40px",
    height: "40px",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.06)",
    },
    "@media (prefers-color-scheme: dark)": {
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      }
    },
    "@media (max-width: 768px)": {
      width: "32px",
      height: "32px",
      padding: "6px",
    }
  },
  scrollContainer: {
    margin: "0 -60px",
    padding: "0 60px",
  },
  singleImageContainer: {
    width: "500px",
    height: "300px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      objectFit: "fill",
      width: "100%",
      height: "100%",
      borderRadius: "8px",
    }
  },
  overviewText: {
    marginBottom: "8px",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "50px",
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    }
  },
  desc: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  descDetail: {
    wordBreak: "break-word",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",
    maxHeight: props => props.expanded ? "none" : "100px",
    overflow: "hidden",
    position: "relative",
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    }
  },
  version: {
    marginTop: 0,
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    }
  },
  media: {
    backgroundSize: "contain",
    height: "290px",
    "@media (max-width: 1279px)": {
      height: "180px"
    }
  },
  lighterText: {
    color: "#666666"
  },
  slides: {
    width: "100%",
    marginRight: theme.spacing.unit * 2,
    marginBottom: "0px",
    position: "relative"
  },
  slideItems: {
    display: "flex",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  slideItem: {
    width: "50%",
    flex: "none",
    marginRight: "16px",
    "@media (min-width: 1280px)": {
      width: "60%"
    }
  },
  slideImg: {
    width: "100%",
    borderRadius: "7px"
  },
  slideBtn: {
    position: "absolute",
    top: "50%",
    transform: "translate(-125%, -25%)",
    transition: "all .2s ease",
    borderRadius: "100%",
    width: "34px",
    height: "34px",
    cursor: "pointer",
    opacity: 0,
    "&.show": {
      opacity: 1
    },
    "&:hover": {
      background: "rgba(0, 0, 0, 0.60)",
      "& svg path": {
        stroke: "#fff"
      }
    }
  },
  showMoreButton: {
    background: "none",
    border: "none",
    color: "#0b9cda",
    cursor: "pointer",
    padding: "8px 0",
    fontSize: "14px",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  infoRow: {
    display: "flex",
    marginBottom: "16px",
  },
  infoItem: {
    display: "flex",
    flexDirection: "column",
    marginRight: "64px",
  },
  infoLabel: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "4px",
    fontWeight: 600,
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    }
  },
  infoValue: {
    fontSize: "14px",
    color: "#000",
    "@media (prefers-color-scheme: dark)": {
      color: "white"
    },
    fontWeight: 400,
  },
});

const AppCard = props => {
  const { detail, loading, classes, appId, intl } = props;
  const [expanded, setExpanded] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [showArrows, setShowArrows] = useState(false); 

  const checkArrowsVisibility = () => {
    const container = document.querySelector('.react-horizontal-scrolling-menu--scroll-container');
    if (container) {
      const shouldShowArrows = container.scrollWidth > container.clientWidth;
      setShowArrows(shouldShowArrows);
    }
  };

  useEffect(() => {
    checkArrowsVisibility();
    
    const container = document.querySelector('.react-horizontal-scrolling-menu--scroll-container');
    if (container) {
      const resizeObserver = new ResizeObserver(checkArrowsVisibility);
      resizeObserver.observe(container);
      
      return () => resizeObserver.disconnect();
    }
  }, [detail && detail.screenshots]);

  const LeftArrow = () => {
    const handleClick = () => {
      const container = document.querySelector('.react-horizontal-scrolling-menu--scroll-container');
      if (container) {
        container.scrollBy({
          left: -800,
          behavior: 'smooth'
        });
      }
    };

    return (
      <div 
        className={classes.arrow} 
        style={{ 
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)'
        }}
        onClick={handleClick}
      >
        <svg width="20" height="20" viewBox="0 0 24 24">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="currentColor"/>
        </svg>
      </div>
    );
  };

  const RightArrow = () => {
    
    const handleClick = () => {
      const container = document.querySelector('.react-horizontal-scrolling-menu--scroll-container');
      if (container) {
        container.scrollBy({
          left: 800,
          behavior: 'smooth'
        });
      }
    };

    return (
      <div 
        className={classes.arrow} 
        style={{ 
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)'
        }}
        onClick={handleClick}
      >
        <svg width="20" height="20" viewBox="0 0 24 24">
          <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z" fill="currentColor"/>
        </svg>
      </div>
    );
  };

  if (loading && !detail) {
    return (
      <div className={classNames(classes.root, classes.loadingContainer)}>
        <Loading width={80} height={80} />
      </div>
    );
  }

  if (!detail || detail.open === false) {
    return (
      <div className={classNames(classes.root, classes.loadingContainer)}>
        {i18n(intl, "getAppInfoError")}
      </div>
    );
  }

  let ScreenshotContainer = null;

  const settings = {
    showArrows:
      detail.screenshots.length > (detail.type.includes(6) ? 3 : 1)
        ? true
        : false,
    showStatus: false,
    showThumbs: false,
    infiniteLoop: true,
    autoPlay: false,
    interval: 5000,
    emulateTouch: true,
    renderArrowPrev: e => {
      if (detail.screenshots.length <= (detail.type.includes(6) ? 3 : 1))
        return null;

      return (
        <div
          className={classNames(
            "arrow",
            "left",
            !showArrow && "hide",
            detail.type.includes(6) && "android"
          )}
          onClick={e}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <rect
              width="30"
              height="30"
              rx="15"
              transform="matrix(-1.19249e-08 -1 -1 1.19249e-08 30 30)"
              fill="black"
              fillOpacity="0.2"
            />
            <path
              d="M16.4301 18.5738L12.3747 14.5184L16.4301 10.463"
              stroke="white"
              strokeWidth="0.955862"
            />
          </svg>
        </div>
      );
    },
    renderArrowNext: e => {
      if (detail.screenshots.length <= (detail.type.includes(6) ? 3 : 1))
        return null;

      return (
        <div
          className={classNames(
            "arrow",
            "right",
            !showArrow && "hide",
            detail.type.includes(6) && "android"
          )}
          onClick={e}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <rect
              y="30"
              width="30"
              height="30"
              rx="15"
              transform="rotate(-90 0 30)"
              fill="black"
              fillOpacity="0.2"
            />
            <path
              d="M13.5699 18.5738L17.6252 14.5184L13.5699 10.463"
              stroke="white"
              strokeWidth="0.955862"
            />
          </svg>
        </div>
      );
    }
  };

  if (detail.screenshots.length > 1) {
    ScreenshotContainer = (
      <div className={classes.screenshotWrapper}>
        <ScrollMenu 
          LeftArrow={showArrows ? LeftArrow : null} 
          RightArrow={showArrows ? RightArrow : null}
          wrapperClassName={classes.scrollContainer}
          scrollContainerClassName="custom-scroll"
          itemClassName={classes.screenshot}
        >
          {detail.screenshots.map((item, index) => (
            <div className={classes.screenshot} key={`${item}-${index}`} itemId={`${item}-${index}`}>
              <img src={item} alt={`Screenshot ${index + 1}`} className={classes.screenshotImage} />
            </div>
          ))}
        </ScrollMenu>
      </div>
    );
  } else if (detail.screenshots.length === 1) {
    ScreenshotContainer = (
      <div className={classes.singleImageContainer}>
        <img 
          src={detail.screenshots[0]} 
          alt="Screenshot" 
          className={classes.screenshotImage} 
        />
      </div>
    );
  }

  const { title, description } = utils.i18nAppInfo(detail, intl.locale);
  const appUrl = `${STORE_URL}/?appid=${appId}`;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.logo}>
          <img src={detail.iconUrl} className={classes.icon} alt={detail.name} />
          <div className={classes.title}>
            <Typography variant="h6" className={classes.appName}>
              {detail.title}
            </Typography>
            <Typography className={classes.devName}>
              {
                detail.author && <span key={detail.author.id} className={classes.lighterText}>
                  {detail.author.name || detail.author.email}
                </span>
              }
            </Typography>
          </div>
        </div>
        <InstallButton appId={appId} detail={detail} />
      </div>
      {ScreenshotContainer}
      <div className={classes.content}>
        <div className={classes.desc}>
          <Typography variant="subtitle2" className={classes.overviewText}>
            {i18n(intl, "introductionTitle", "Overview")}
          </Typography>
          <div style={{ position: "relative" }}>
            <div
              className={classes.descDetail}
              style={{
                maxHeight: expanded ? "none" : "100px",
                overflow: "hidden"
              }}
              dangerouslySetInnerHTML={{
                __html: description.replace(/\n(\n)?/g, "<br/>")
              }}
            />
            {description && description.length > 200 && (
              <button
                className={classes.showMoreButton}
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? i18n(intl, "showLess", "showLess") : i18n(intl, "showMore", "showMore")}
              </button>
            )}
          </div>
          <Divider className={classes.divider} />
          <Typography variant="subtitle2" className={classes.overviewText}>
            {i18n(intl, "details", "Details")}
          </Typography>
            <div className={classes.infoRow}>
              <div className={classes.infoItem}>
                <div className={classes.infoLabel}>{i18n(intl, "version", "Version")}</div>
                <div className={classes.infoValue}>{(detail && detail.version) || '-'}</div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoLabel}>{i18n(intl, "lastUpdate", "Last Update")}</div>
                <div className={classes.infoValue}>
                {detail && detail.updatedAt 
                  ? new Date(detail.updatedAt).toLocaleDateString() 
                  : '-'}
              </div>
              </div>
              <div className={classes.infoItem}>
                <div className={classes.infoLabel}>{i18n(intl, "Id", "Id")}</div>
                <div className={classes.infoValue}>
                {(detail && (detail.extensionId || detail.webUrl || detail.packageName)) || '-'}
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

AppCard.propTypes = {
  appId: PropTypes.string.isRequired,
  detail: PropTypes.shape({
    appId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired
  }),
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    loadingContainer: PropTypes.string,
    progress: PropTypes.string,
    header: PropTypes.string,
    title: PropTypes.string,
    appName: PropTypes.string,
    icon: PropTypes.string,
    desc: PropTypes.string,
    content: PropTypes.string,
    slides: PropTypes.string,
    logo: PropTypes.string
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired
};

AppCard.defaultProps = {
  detail: null,
  loading: false,
  classes: {}
};

const mapStateToProps = state => {
  const { appList, appDetails } = state.app;
  const appId = appList.selected;
  const appDetail = appDetails[appId] || {};
  
  return {
    appId,
    detail: appDetail.data,
    loading: appDetail.loading
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(injectIntl(AppCard))
);
